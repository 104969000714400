<template>
  <div id="smartai_comment_manage_page" style="margin: 20px" v-cloak>
    <el-tabs v-model="tabActiveName" type="border-card">
      <el-tab-pane class="tab_plane" label="学校列表" name="schooltabpane">
        <SearchToolbar
          :is_show_class="false"
          :is_show_username="false"
          :is_show_teacher="false"
          :is_show_btn_export="false"
          :is_show_btn_statistics="false"
          :school_list="school_list"
          @st-search-click="school_search"
        >
        </SearchToolbar>
        <div>
          <el-table :data="schoolTableData" style="width: 100%">
            <el-table-column prop="school_id" label="序号" width="100">
            </el-table-column>
            <el-table-column prop="school_name" label="学校名称">
            </el-table-column>
            <el-table-column prop="class_num" label="班级数量">
            </el-table-column>
            <el-table-column prop="member_num" label="学生数量">
            </el-table-column>
            <el-table-column prop="teacher_num" label="老师数量">
            </el-table-column>
            <el-table-column label="查看教师评分">
              <template slot-scope="scope">
                <el-link
                  type="success"
                  :underline="false"
                  @click="viewteacherrating(scope.row.school_id)"
                  >查看教师评分</el-link
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            small
            style="margin-top: 15px"
            background
            layout="prev, pager, next"
            @current-change="handleSchoolCurrentChange"
            :page-size="schoolPagesize"
            :total="schoolTotalCount"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane class="tab_plane" label="教师评分" name="teachertabpane">
        <SearchToolbar
          ref="teachersearchtoolbar"
          :is_show_btn_statistics="false"
          :school_list="school_list"
          :class_list="class_list"
          @st-search-click="teacher_search"
          @st-export-click="teacher_export"
        >
        </SearchToolbar>
        <el-table
          :data="teacherTableData"
          v-loading="teacherLoading"
          style="width: 100%"
        >
          <el-table-column prop="member_id" label="序号"> </el-table-column>
          <el-table-column prop="member_name" label="用户名"> </el-table-column>
          <el-table-column prop="school_name" label="学校名称">
          </el-table-column>
          <el-table-column prop="class_name" label="班级名称">
          </el-table-column>
          <el-table-column prop="teacher_default_score" label="默认分值">
          </el-table-column>
          <el-table-column prop="teacher_status" label="教师评分状态">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.teacher_status == 1" type="success"
                >教师已评</el-tag
              >
              <el-tag v-if="scope.row.teacher_status == 0" type="danger"
                >教师未评</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column prop="teacher_score" label="教师评分">
          </el-table-column>
          <el-table-column prop="teacher_components" label="教师评语">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-link
                type="success"
                :underline="false"
                @click="handleExamStudent(scope.row.member_id)"
                >查看</el-link
              >
              <el-link
                type="success"
                :underline="false"
                style="margin-left: 10px"
                @click="openTeacherScore(scope.row)"
                >评分</el-link
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          small
          style="margin-top: 15px"
          background
          layout="prev, pager, next"
          @current-change="handleTeacherCurrentChange"
          :page-size="teacherPagesize"
          :total="teacherTotalCount"
        >
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
    <el-dialog title="教师评分" :visible.sync="dialogFormVisible">
      <el-form :model="teacher_form">
        <el-form-item label="分数" :label-width="formLabelWidth">
          <el-input
            type="number"
            v-model="teacher_form.score"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="教师评语" :label-width="formLabelWidth">
          <el-input
            type="textarea"
            v-model="teacher_form.components"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveTeacherScore()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import SearchToolbar from "@/components/review/search_compontent.vue";
import {
  getSchoolList,
  getCommentSchoolList,
  getTeacherPointList,
  saveTeacherScore,
  exportTeacherScore,
  examStudentLogin,
} from "@/api/admin.js";
import funDownload from "@/utils/exportExcel.js";
import {
  setBAuth,
  // setSAuth
} from "@/utils/auth.js";
export default {
  components: {
    SearchToolbar,
  },
  data() {
    return {
      search_data: {},
      tabActiveName: "schooltabpane",
      school_list: [],
      schoolTableData: [],
      schoolTotalCount: 0,
      schoolPage: 1,
      schoolPagesize: 10,
      teacher_school_id: "",
      class_list: [],
      teacherTableData: [],
      teacherTotalCount: 0,
      teacherPage: 1,
      teacherPagesize: 10,
      teacherLoading: false,
      dialogFormVisible: false,
      max_score: 0,
      teacher_form: {},
      formLabelWidth: "120px",
    };
  },
  methods: {
    teacher_export: function (data) {
      exportTeacherScore({
        school_id: data.school_id,
        class_id: data.class_id,
        username: data.username,
        teacher_status: data.teacher_status,
      }).then((res) => {
        let time = Date.now();
        time = this.formatTimeA(time, 1);
        funDownload(res, "教师评分-" + time + ".xlsx");
        this.$message.success("下载成功");
      });
    },
    handleExamStudent: function (id) {

      let vm = this;
      examStudentLogin({
        member_id: id,
      }).then((res) => {
        if (res.code == 1) {
          vm.$confirm("确认对该考试进行评分吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              setBAuth(res.data.token);
              window.open(
                "/home?act=score_smartai&op=exam_student_login&member_id=" + id
              );
            })
            .catch(() => {
              vm.$message("已取消评分!");
            });
        }
      });
    },
    saveTeacherScore: function () {
      let vm = this;
      if (this.teacher_form.score == "") {
        this.$message.error("分数不能为空");
        return false;
      }
      if (parseFloat(this.teacher_form.score) > parseFloat(this.max_score)) {
        this.$message.error("不能大于最大的" + this.max_score + "分");
        return false;
      }
      saveTeacherScore({
        school_student_string: this.teacher_form.school_student_string,
        school_id: this.teacher_form.school_id,
        class_id: this.teacher_form.class_id,
        score: this.teacher_form.score,
        components: this.teacher_form.components,
      }).then((res) => {
        if (res.code == 1) {
          vm.$message.success("评分成功");
          vm.getTeacherPointList();
          vm.dialogFormVisible = false;
        }
      });
      // $.ajax({
      //     type: 'POST',
      //     url: 'index.php?act=score_smartai&op=saveteacherscore',
      //     data:{
      //        teacher_data:vm.teacher_form
      //     },
      //     dataType: 'json',
      //     success: function(res) {
      //         if (res.code == 200) {
      //             vm.$message.success('评分成功');
      //             vm.getTeacherPointList();
      //             vm.dialogFormVisible=false;
      //         }
      //     }
      // })
    },
    openTeacherScore: function (arr) {
      this.teacher_form = {
        school_student_string: arr.school_student_string,
        school_id: arr.school_id,
        class_id: arr.class_id,
        score: "",
        components: "",
      };
      this.max_score = arr.teacher_default_score;
      this.dialogFormVisible = true;
    },
    teacher_search: function (data) {
      this.search_data = data;
      this.teacherPage = 1;
      this.getTeacherPointList();
    },
    school_search: function (data) {
      this.search_data = data;
      this.schoolPage = 1;
      this.getSchoolList();
    },
    viewteacherrating: function (school_id) {
      this.tabActiveName = "teachertabpane";
      this.teacher_school_id = school_id;
      this.teacherPage = 1;
      this.getTeacherPointList();
      this.$refs.teachersearchtoolbar.setSchoolId(school_id);
    },
    handleTeacherCurrentChange: function (val) {
      this.teacherPage = val;
      this.getTeacherPointList();
    },
    handleSchoolCurrentChange: function (val) {
      this.schoolPage = val;
      this.getSchoolList();
    },
    getTeacherPointList: function () {
      let vm = this;
      vm.teacherLoading = true;
      getTeacherPointList({
        school_id: this.search_data.search_data,
        class_id: this.search_data.class_id,
        username: this.search_data.username,
        teacher_status: this.search_data.teacher_status,
        page: this.teacherPage,
        page_size: this.teacherPagesize,
      }).then((res) => {
        if (res.code == 1) {
          vm.teacherTableData = res.data.list;
          vm.teacherTotalCount = parseInt(res.data.total);
          vm.teacherLoading = false;
        }
      });
    },
    getSchoolList: function () {
      getCommentSchoolList({
        school_id: this.search_data.school_id,
        page: this.schoolPage,
        page_size: this.schoolPagesize,
      }).then((res) => {
        if (res.code == 1) {
          this.schoolTableData = res.data.list;
          this.schoolTotalCount = parseInt(res.data.total);
        }
      });
    },
    getSList() {
      getSchoolList().then((res) => {
        if (res.code == 1) {
          this.school_list = res.data;
        }
      });
    },
  },
  created: function () {
    this.getSList();
    this.getSchoolList();
    this.getTeacherPointList();
  },
};
</script>