<template id="search_toolbar">
  <div class="demo-input-suffix">
    <span v-if="is_show_school">学校名称：</span>
    <el-select
      size="small"
      v-if="is_show_school"
      v-model="search_data.school_id"
      clearable
      filterable
      placeholder="请选择学校"
      @change="handleSchoolChange"
    >
      <el-option
        v-for="(sc_item, sc_index) in school_list"
        :key="sc_index"
        :label="sc_item.school_name"
        :value="parseInt(sc_item.id)"
        >{{ sc_item.school_name }}</el-option
      >
    </el-select>
    <span v-if="is_show_class" style="margin-left:10px">所属班级：</span>
    <el-select
    style="width:180px"
      size="small"
      v-if="is_show_class"
      v-model="search_data.class_id"
      clearable
      filterable
      placeholder="请选择"
    >
      <el-option
        v-for="(class_item, class_index) in comClassList"
        :key="class_index"
        :label="class_item.class_name"
        :value="class_item.id"
        >{{ class_item.class_name }}</el-option
      >
    </el-select>
    <span v-if="is_show_username" style="margin-left:10px">用户名：</span
    ><el-input
      size="small"
      v-if="is_show_username"
      v-model="search_data.username"
      style="width: 120px"
      placeholder="请输入内容"
    ></el-input>
    <span v-if="is_show_teacher" style="margin-left:10px">教师评分状态：</span>
    <el-select
      size="small"
      v-model="search_data.teacher_status"
      v-if="is_show_teacher"
      clearable
      filterable
      placeholder="请选择"
    >
      <el-option :value="1" label="已评分">已评分</el-option>
      <el-option :value="0" label="待评分">待评分</el-option>
    </el-select>
    <el-button
      size="small"
      v-if="is_show_btn_search"
      type="primary"
      @click="searchClick"
      style="margin-left: 10px"
      >查询</el-button
    >
    <el-button v-if="is_show_btn_statistics" type="success" size="small"
      >一键统计得分</el-button
    >
    <el-button
      size="small"
      v-if="is_show_btn_export"
      type="success"
      @click="exportClick"
      >导出</el-button
    >
    <el-divider></el-divider>
  </div>
</template>
<script>
import { getClassList } from "@/api/admin.js";
export default {
  name: "SearchToolbar",
  props: {
    is_show_school: {
      type: Boolean,
      default: true,
    },
    is_show_class: {
      type: Boolean,
      default: true,
    },
    is_show_username: {
      type: Boolean,
      default: true,
    },
    is_show_teacher: {
      type: Boolean,
      default: true,
    },
    is_show_btn_search: {
      type: Boolean,
      default: true,
    },
    is_show_btn_statistics: {
      type: Boolean,
      default: true,
    },
    is_show_btn_export: {
      type: Boolean,
      default: true,
    },
    school_list: {
      type: Array,
      default() {
        return [];
      },
    },
    class_list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      comClassList: [],
      search_data: {
        school_id: "",
        class_id: "",
        username: "",
        teacher_status: "",
      },
    };
  },
  methods: {
    exportClick: function () {
      this.$emit("st-export-click", this.search_data);
    },
    searchClick: function () {
      this.$emit("st-search-click", this.search_data);
    },
    setSchoolId: function (school_id) {
      this.search_data.school_id = school_id;
      this.handleSchoolChange();
    },
    handleSchoolChange: function () {

      getClassList({
        school_id: this.search_data.school_id,
      }).then((res) => {
        if (res.code == 1) {
          this.comClassList = res.data;
          this.search_data.class_id = "";
        }
      });
    },
  },
  watch: {},
  created: function () {
    this.comClassList = this.class_list;
  },
};
</script>